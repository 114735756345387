const days = [
  { value: '02', label: 'Segunda-Feira' },
  { value: '03', label: 'Terça-Feira' },
  { value: '04', label: 'Quarta-Feira' },
  { value: '05', label: 'Quinta-Feira' },
  { value: '06', label: 'Sexta-Feira' },
];

const months = [
  { value: '01', label: 'Janeiro' },
  { value: '02', label: 'Fevereiro' },
  { value: '03', label: 'Março' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Maio' },
  { value: '06', label: 'Junho' },
  { value: '07', label: 'Julho' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
];

const weeks = [
  { value: '01', label: 'Primeira' },
  { value: '02', label: 'Segunda' },
  { value: '03', label: 'Terceira' },
  { value: '04', label: 'Quarta' },
];

export { days, months, weeks };
