const evaluations = [
  {
    value: 0,
    label: 'Não se aplica',
  },
  {
    value: 1,
    label: 'Muito insatisfeito',
  },
  {
    value: 2,
    label: 'Insatisfeito',
  },
  {
    value: 3,
    label: 'Regular',
  },
  {
    value: 4,
    label: 'Satisfeito',
  },
  {
    value: 5,
    label: 'Muito Satisfeito',
  },
];

export { evaluations };
