import { useSelector } from 'react-redux';
import administrationCenter from './administrationCenter';
import configurationCenter from './configurationCenter';
import panelCenter from './panelCenter';
import registrationCenter from './registrationCenter';
import reportCenter from './reportCenter';
import utilities from './utilities';
import adm from './adm';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  items: [adm, configurationCenter, administrationCenter],
};

export default menuItems;
