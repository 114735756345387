import administrationCenter from './administrationCenter';
import utilities from './utilities';
import vi from './vi';

// ==============================|| MENU ITEMS ||============================== //
const menuVi = {
  items: [vi, administrationCenter],
};

export default menuVi;
