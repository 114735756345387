export const floors = [
  { id: '1º Andar', label: '1º Andar' },
  { id: '2º Andar', label: '2º Andar' },
  { id: '3º Andar', label: '3º Andar' },
  { id: '4º Andar', label: '4º Andar' },
  { id: '5º Andar', label: '5º Andar' },
  { id: '6º Andar', label: '6º Andar' },
  { id: '7º Andar', label: '7º Andar' },
  { id: '8º Andar', label: '8º Andar' },
  { id: '9º Andar', label: '9º Andar' },
  { id: '10º Andar', label: '10º Andar' },
  { id: '11º Andar', label: '11º Andar' },
  { id: '12º Andar', label: '12º Andar' },
  { id: '13º Andar', label: '13º Andar' },
  { id: '14º Andar', label: '14º Andar' },
  { id: '15º Andar', label: '15º Andar' },
  { id: '16º Andar', label: '16º Andar' },
  { id: '17º Andar', label: '17º Andar' },
  { id: '17º Andar', label: '17º Andar' },
  { id: '18º Andar', label: '18º Andar' },
  { id: '19º Andar', label: '19º Andar' },
  { id: '20º Andar', label: '20º Andar' },
  { id: '21º Andar', label: '21º Andar' },
  { id: '22º Andar', label: '22º Andar' },
  { id: '23º Andar', label: '23º Andar' },
  { id: '24º Andar', label: '24º Andar' },
  { id: '25º Andar', label: '25º Andar' },
  { id: '26º Andar', label: '26º Andar' },
  { id: '27º Andar', label: '27º Andar' },
  { id: '28º Andar', label: '28º Andar' },
  { id: '29º Andar', label: '29º Andar' },
  { id: '30º Andar', label: '30º Andar' },
];
