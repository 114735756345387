import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    Alert,
    Snackbar,
    MenuItem,
    Autocomplete,
    IconButton
} from '@mui/material';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import MoreVertIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getResourceOperating, getOperatingPerId, postOperating, updateOperating } from 'services/operating';
import { getMaintenancePlanPerId, getResourceMaintenancePlan, postMaintenancePlan } from 'services/maintenancePlan';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const MaintenancePlan = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const initialValuesEdit = {
        name: '',
        idCategory: '',
        idUnit: '',
        floor: ''
    };
    const initialStateOptions = {
        task: [],
        frequency: []
    };
    const initialMaintenance = {
        id: '',
        task: '',
        frequency: '',
        amount: '',
        initialDate: '',
        active: true
    };
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [options, setOptions] = useState(initialStateOptions);
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [errorValidate, setErrorValidate] = useState({});
    const [operating, setOperating] = useState('');
    const isDisabled = params.action === 'view' ? true : false;
    const [maintenance, setMaintenance] = useState([]);
    const [currentMaintenance, setCurrentMaintenance] = useState(initialMaintenance);
    const idUnit = useSelector((state) => state.user.unit || '');
    useEffect(() => {
        viewPerId();
    }, [params.idOperating]);

    useEffect(() => {
        getOperating();
    }, [params.idOperating]);
    useEffect(() => {
        getResource();
    }, []);
    function viewPerId() {
        getMaintenancePlanPerId(params.idOperating).then((resp) => {
            setMaintenance(
                resp.data.map((desc) => ({
                    id: desc.id,
                    task: { id: desc.idTask, label: desc.task },
                    frequency: { id: desc.idFrequency, label: desc.frequency },
                    amount: desc.amount,
                    initialDate: desc.initialDate,
                    active: desc.active === 1 ? true : false
                }))
            );
        });
    }
    const handleChangeInput = (e) => {
        let name = e.target.name;
        setCurrentMaintenance({ ...currentMaintenance, [name]: e.target.value });
    };
    function getOperating() {
        getOperatingPerId(params.idOperating).then((resp) => setOperating({ id: resp.data.id, label: resp.data.nome }));
    }
    function getResource() {
        getResourceMaintenancePlan().then((resp) => setOptions(resp.data));
    }

    const handleMaintenance = () => {
        let maintenanceObject = { ...currentMaintenance, id: uniqueId() };
        setError('');
        setMaintenance([...maintenance, maintenanceObject]);
        setCurrentMaintenance(initialMaintenance);
    };
    const handleDeleteMaintenance = (id) => {
        setMaintenance(maintenance.filter((desc) => parseInt(desc.id) !== parseInt(id)));
    };
    function renderMaintenance() {
        let maintenanceRender = maintenance || [];
        return maintenanceRender.map((desc) => (
            <Grid key={desc.id} container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={5} sx={{ marginTop: 3 }}>
                    <Autocomplete
                        fullWidth
                        select
                        label="Tarefa"
                        id="task"
                        value={desc.task}
                        name="task"
                        // onBlur={handleBlur}
                        // onChange={(e, newValue) =>
                        //     setCurrentMaintenance({ ...currentMaintenance, task: newValue })
                        // }
                        options={options.task}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tarefa"
                                // helperText={touched.idTask && errors.idTask ? errors.idTask : ''}
                                // error={Boolean(touched.idTask && errors.idTask)}
                            />
                        )}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                    <Autocomplete
                        fullWidth
                        select
                        label="Frequência"
                        id="frequency"
                        value={desc.frequency}
                        name="frequency"
                        // onBlur={handleBlur}
                        // onChange={(e, newValue) =>
                        //     setCurrentMaintenance({ ...currentMaintenance, frequency: newValue })
                        // }
                        options={options.frequency}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Frequência"
                                // helperText={touched.idFrequency && errors.idFrequency ? errors.idFrequency : ''}
                                // error={Boolean(touched.idFrequency && errors.idFrequency)}
                            />
                        )}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={1} sx={{ marginTop: 3 }}>
                    <NumberFormat
                        fullWidth
                        id="outlined-quantidade"
                        type="text"
                        label="Quantidade"
                        value={desc.amount}
                        disabled={true}
                        // onChange={handleChangeInput}
                        // onBlur={handleBlur}
                        name="amount"
                        customInput={TextField}
                        // isAllowed={(values) => {
                        //     const { floatValue, formattedValue } = values;
                        //     return formattedValue === '' || (floatValue >= 1 && floatValue <= 99);
                        // }}
                    />
                </Grid>
                <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                    <TextField
                        fullWidth
                        id="outlined-date"
                        type="date"
                        label="Desde"
                        // onChange={handleChangeInput}
                        value={desc.initialDate}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        name="initialDate"
                        disabled={true}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={1} sx={{ marginTop: 5 }}>
                    <Toggle disabled={true} style={{ margin: '20px' }} checked={desc.active} />
                </Grid>
                <Grid item xs={12} sm={1} sx={{ marginTop: 4 }}>
                    <IconButton aria-label="remover" onClick={() => handleDeleteMaintenance(desc.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        ));
    }
    return (
        <>
            <Formik
                initialValues={{
                    // name: valuesEdit.name,
                    // idCategory: valuesEdit.idCategory,
                    // idUnit: valuesEdit.idUnit,
                    // floor: valuesEdit.floor,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    // name: Yup.string().max(255).trim().required('Nome do Ativo obrigatório'),
                    // idUnit: Yup.object().required('Unidade obrigatório'),
                    // idCategory: Yup.object().required('Categoria obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        const data = { task: maintenance, idUnit: idUnit, idOperating: parseInt(params.idOperating) };
                        console.log(data);
                        setLoading(true);
                        if (params.action === 'edit') {
                            updateOperating(params.id, data)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                    setTimeout(() => {
                                        setError('');
                                    }, 3000);
                                });
                        } else {
                            postMaintenancePlan(data)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        viewPerId();
                                        setSuccess('');
                                    }, 2000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setError(e.response.data.error);
                                    setTimeout(() => {
                                        setError('');
                                    }, 3000);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            <Grid xs={12} md={12} sm={12} container>
                                <h1
                                    style={{
                                        font: 'normal normal bold 35px/44px Myriad Pro',
                                        letterSpacing: '0px',
                                        color: 'var(--unnamed-color-015641)',
                                        Color: '#015641',
                                        opacity: 1,
                                        padding: 15,
                                        marginLeft: '2%'
                                    }}
                                >
                                    PLANO DE MANUTENÇÃO
                                </h1>
                                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                                {/* <h3
                                    style={{
                                        font: 'normal normal 300 18px/22px Myriad Pro',
                                        letterSpacing: '0px',
                                        Color: '#00000',
                                        opacity: 1,
                                        padding: 15,
                                        marginLeft: '2%'
                                    }}
                                >
                                    Gerencie os serviços
                                </h3> */}
                            </Grid>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <Grid container spacing={matchDownSM ? 0 : 2} sx={{ mb: 5 }}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <Autocomplete
                                                fullWidth
                                                select
                                                label="Ativo"
                                                id="idOperating"
                                                value={operating}
                                                name="idOperating"
                                                onBlur={handleBlur}
                                                options={[]}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Ativo"
                                                        helperText={touched.idOperating && errors.idOperating ? errors.idOperating : ''}
                                                        error={Boolean(touched.idOperating && errors.idOperating)}
                                                    />
                                                )}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <h3>Adicionar Tarefa</h3>
                                    <hr></hr>
                                    {!params.action && (
                                        <Grid container spacing={matchDownSM ? 0 : 2} sx={{ mb: 10 }}>
                                            <Grid item xs={12} sm={5} sx={{ marginTop: 3 }}>
                                                <Autocomplete
                                                    fullWidth
                                                    select
                                                    label="Tarefa"
                                                    id="task"
                                                    value={currentMaintenance.task}
                                                    name="task"
                                                    onBlur={handleBlur}
                                                    onChange={(e, newValue) =>
                                                        setCurrentMaintenance({ ...currentMaintenance, task: newValue })
                                                    }
                                                    options={options.task}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Tarefa"
                                                            helperText={touched.idTask && errors.idTask ? errors.idTask : ''}
                                                            error={Boolean(touched.idTask && errors.idTask)}
                                                        />
                                                    )}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                                                <Autocomplete
                                                    fullWidth
                                                    select
                                                    label="Frequência"
                                                    id="frequency"
                                                    value={currentMaintenance.frequency}
                                                    name="frequency"
                                                    onBlur={handleBlur}
                                                    onChange={(e, newValue) =>
                                                        setCurrentMaintenance({ ...currentMaintenance, frequency: newValue })
                                                    }
                                                    options={options.frequency}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Frequência"
                                                            helperText={touched.idFrequency && errors.idFrequency ? errors.idFrequency : ''}
                                                            error={Boolean(touched.idFrequency && errors.idFrequency)}
                                                        />
                                                    )}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={1} sx={{ marginTop: 3 }}>
                                                <NumberFormat
                                                    fullWidth
                                                    id="outlined-quantidade"
                                                    type="text"
                                                    label="Quantidade"
                                                    value={currentMaintenance.amount}
                                                    onChange={handleChangeInput}
                                                    // onBlur={handleBlur}
                                                    name="amount"
                                                    customInput={TextField}
                                                    isAllowed={(values) => {
                                                        const { floatValue, formattedValue } = values;
                                                        return formattedValue === '' || (floatValue >= 1 && floatValue <= 99);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.date && errors.date)}
                                                    id="outlined-date"
                                                    type="date"
                                                    label="Desde"
                                                    onChange={handleChangeInput}
                                                    value={currentMaintenance.initialDate}
                                                    // onChange={handleChange}
                                                    // onBlur={handleBlur}
                                                    name="initialDate"
                                                    disabled={isDisabled}
                                                    helperText={touched.date && errors.date ? errors.date : ''}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={1} sx={{ marginTop: 5 }}>
                                                <Toggle
                                                    style={{ margin: '20px' }}
                                                    checked={currentMaintenance.active}
                                                    onChange={(e) =>
                                                        setCurrentMaintenance({ ...currentMaintenance, active: e.target.checked })
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={1} sx={{ marginTop: 4 }}>
                                                <IconButton aria-label="adicionar" onClick={handleMaintenance}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <h3>Tarefas</h3>
                                    <hr></hr>
                                    {renderMaintenance()}
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/ativo/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default MaintenancePlan;
