import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export default function OppositeContentTimeline({ timeline = [] }) {
    let itens = timeline || [];
    return (
        <React.Fragment>
            <Timeline position="alternate">
                {itens.map((desc) => (
                    <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">{desc.created_at}</TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={desc.color} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <p>
                                <strong>{desc.description}</strong>
                            </p>
                            {desc.url && (
                                <a href={desc.url} target="_blank">
                                    <img
                                        style={{
                                            width: '80px',
                                            height: '80px'
                                        }}
                                        src={desc.url}
                                        alt="evidencia"
                                    />
                                </a>
                            )}
                            {desc.descricao_atendimento && desc.descricao_atendimento !== 'null' && <p>{desc.descricao_atendimento}</p>}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </React.Fragment>
    );
}
